import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function IndexLinks({ children, text = "", to, type = "external" }) {
  const styles =
    "bg-gradient-to-r hover:from-brand-orange border-l-3 last:border-r-3 border-brand-orange px-5 md:py-2 lg:py-5 text-xs sm:text-base sm:leading-relaxed sm:tracking-wider";

  const content = (
    <>
      <svg
        className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 inline fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {children}
      </svg>
      <span className="hidden sm:inline font-normal sm:pl-2">{text}</span>
    </>
  );

  let container;
  if (type === "internal") {
    container = (
      <Link className={styles} to={to}>
        {content}
      </Link>
    );
  }
  if (type === "external") {
    container = (
      <a href={to} className={styles}>
        {content}
      </a>
    );
  }

  return container;
}

IndexLinks.PropTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.string
};

export default IndexLinks;
