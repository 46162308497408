import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MultiBackground from "../components/multibackground";
import LegalLink from "../components/legal_link";
import IndexLinks from "../components/index_links";

function IndexPage({ data }) {
  const markdown = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const formattedIntro = markdown.intro
    .split("\n\n")
    .map(paragraph => `<p>${paragraph.replace(/\n/g, "<br>")}</p>`)
    .join("");

  return (
    <Layout>
      <SEO />

      <MultiBackground className="hero-chat h-screen">
        <section className="max-w-4xl px-8 sm:px-20 xl:px-0 mx-auto text-white">
          <h1 className="mt-8 sm:-mt-8 mb-2 sm:mb-2-5 pl-0 -ml-1-25">
            {markdown.title}
          </h1>

          <div
            className="mb-3 sm:mb-5 py-2 sm:py-8 pr-5 text-xs sm:text-base sm:leading-relaxed sm:tracking-wider"
            dangerouslySetInnerHTML={{ __html: formattedIntro }}
          />
          <div className="flex justify-center">
            <IndexLinks to="/about" type="internal" text="Kennenlernen">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </IndexLinks>
            <IndexLinks to="tel:+49.172.4988247" text="Anrufen">
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </IndexLinks>
            <IndexLinks to="mailto:web@petra-pflug.de" text="Schreiben">
              <path
                fillRule="evenodd"
                d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                clipRule="evenodd"
              />
            </IndexLinks>
          </div>
        </section>
        <LegalLink className="text-gray-300" />
      </MultiBackground>
      <div className="trigger-for-scroll block h-px"></div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "home" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              intro
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;
